import * as React from "react"
import {LocalizedLink as Link} from "gatsby-theme-i18n"
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import {useTranslation} from "react-i18next";

const NotFoundPage = () => {
    const {t} = useTranslation("404");
    return (<>
            <Seo title={`${t("notFound")} - 404`}/>
            <div className="flex flex-col h-full">
                <NavBar title={t("notFound")}/>
                <div className="flex flex-col justify-center items-center flex-grow space-y-10 mx-5">
                    <h1 className="animate-head-shake text-white text-center text-6xl lg:text-8xl">404</h1>
                    <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("notFoundLong")}</h1>
                    <Link to="/"
                          className="text-center text-4xl md:text-5xl lg:text-7xl text-orange">{t("goHome")}</Link>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage
